<template>
<div>
  <section v-if="data.length > 0">
    <splide :options="options" :key="mathRND">
      <splide-slide v-for="(item, i) in data" :key="i">
        <div class="col px-4 py-8 rounded-xl mr-7" style="cursor:pointer;" @click="$router.push('/konten?id=' + item.id)">
          <b-card
            no-body
            class="overflow-hidden card-custom-bg"
            style="max-width: 100%; height: 178px;"
          >
            <b-row no-gutters class="p-0">
              <b-col md="12" class="p-0">
                <b-card-body class="carousel-body" style="width:400px;">
                  <b-badge class="width-badgee carousel-badge red" href="#"
                    >{{ item.kategori.name}}</b-badge
                  >
                  <p class="carousel-title">{{item.title.length > 80 ? item.title.substring(0,80)+ '...' : item.title}}</p>
                  <p class="p-custom">{{item.content.length > 200 ? item.content.substring(0,200)+ '...' : item.content}}</p>
                  <!-- <img src="/assets/images/slide1.svg" alt="" style="position: absolute;top: 0;right: 0;z-index: 0;" /> -->
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </splide-slide>
    </splide>
  </section>
</div>
  
</template>
<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
export default {
  components: {
    Splide,
    SplideSlide,
  },
  props:['data', 'kategori'],
  data() {
    return {
      options: {
        rewind: true,
        perPage: 3,
        arrows: false,
        fixedWidth : 400,
        autoplay : true,
        gap:0,
        pagination:false,
        cover: true,
        // height:178,
        pauseOnHover: false,
        mathRND: 0
      },
    };
  },
  watch: {
    data(to){
      if(to.length > 2) {
        this.options.arrows = true
        this.mathRND = Math.random()
        this.$forceUpdate()
      }
    }
  },
  methods: {
  },
};
</script>

<style>
.width-badgee {
  /* width: 109px; */
  height: 35px;
  font-size: 14px;
  line-height: 19px;
}

.splide__arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 2em;
    height: 2em;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: none;
    padding: 0;
    opacity: 1 !important;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgb(0 0 0 / 19%);
    border-radius: 5px;
}

.carousel-body {
  padding-top:16px;padding-bottom:32px; padding-left:24px; position:relative; background-image:url('/assets/images/slide1.svg'); background-position:top right;
}

.carousel-badge.red{
background-color: #F0CECE; color: #000;
}

.carousel-badge.yellow{
background-color:#EFF0CE; color: #000;
}

.carousel-badge.blue {
background-color:#CEE2F0; color: #000
}
.carousel-title{
z-index:1;padding-top:5px; margin:0px;font-size:16px;font-weight:600;line-height:22px; padding-bottom:8px;
}
</style>