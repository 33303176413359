<template>
  <div>
    <!--begin::Dashboard-->

    <section v-if="listCMSInformasi.length > 0 || listCMSKegiatan.length > 0">
    <div class="row">
      <div class="col-xxl-12">
        <Carousel :data="listCMSInformasi" :options="opt" :kategori="listKategori" />
      </div>
    </div>

    <div class="row pb-5">
      <div class="col-12 d-flex justify-content-between align-items-end">
        <p style="font-weight: 600;font-size: 24px;margin:0px;">Kegiatan</p> 
        <!-- <router-link
          to="/konten"
          v-slot="{ href, navigate, }"
        > -->
          <a href="/konten" class="menu-link" v-if="listCMSKegiatan.length > 0" >
            <span class="menu-text ">Lihat semua</span>
          </a>
        <!-- </router-link> -->
      </div>
    </div>
    <section v-if="listCMSKegiatan.length < 1" class="d-flex justify-content-center align-items-center">

        <h1 class="text-muted">
        - Belum Ada Konten -
        </h1>
      </section>
    <b-row v-if="listCMSKegiatan.length > 0">
      
      <b-col cols="6" v-for="(item,i) in listCMSKegiatan" :key="i">
        <b-card
          no-body
          class="overflow-hidden card-custom-bg mb-10"
          style="max-width: 100%; cursor:pointer;height:250px;"
          @click="$router.push('/konten?id=' + item.id)"
        >
          <b-row>
            <b-col md="6">
              <!-- <img v-if="item.path_image == null"
                src="data:image/png;base64,
                iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAMFBMVEXp7vG6vsG3u77s8fTCxsnn7O/f5OfFyczP0
                9bM0dO8wMPk6ezY3eDd4uXR1tnJzdBvAX/cAAACVElEQVR4nO3b23KDIBRA0ShGU0n0//+2KmO94gWZ8Zxmr7fmwWEHJsJUHw8AAAAAAAAAAAAAAAA
                AAAAAAAAAAAAAAAAAwO1MHHdn+L3rIoK6eshsNJ8kTaJI07fERPOO1Nc1vgQm2oiBTWJ+d8+CqV1heplLzMRNonED+4mg7L6p591FC+133/xCRNCtd3
                nL9BlxWP++MOaXFdEXFjZ7r8D9l45C8y6aG0cWtP/SUGhs2d8dA/ZfGgrzYX+TVqcTNRRO9l+fS5eSYzQs85psUcuzk6igcLoHPz2J8gvzWaH/JLS+9
                5RfOD8o1p5CU5R7l5LkfKEp0mQ1UX7hsVXqDpRrifILD/3S9CfmlUQFhQfuFu0STTyJ8gsP3PH7GVxN1FC4t2sbBy4TNRTu7LyHJbqaqKFw+/Q0ncFloo7Cj
                RPwMnCWqKXQZ75El4nKC9dmcJaou9AXOE5UXbi+RGeJygrz8Uf+GewSn9uXuplnWDZJ7d8f24F/s6iq0LYf9olbS3Q8i5oKrRu4S9ybwaQ/aCkqtP3I28QDge
                oK7TBya/aXqL5COx67PTCD2grtdOwH+pQV2r0a7YVBgZoKwwIVFQYG6ikMDVRTGByopjD8ATcKb0UhhRTe77sKs2DV7FKSjId18TUEBYVyLhUThWfILHTDqmI85/2
                RWWjcE/bhP6OD7maT3h20MHsA47JC3PsW0wcwLhv9t0OOPOIkCn21y2bXXwlyylxiYMPk1SuCSmpfK8bNQvIrpAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADwNX4B
                CbAju9/X67UAAAAASUVORK5CYII="
                class="round-image-dash" style="margin:16px;"
              > -->
              <img :src="fileURL + '/' + item.path_image" alt="" style="width:250px;height:250px;" @error="replaceByDefault">
            </b-col>
            <b-col md="6" class="pl-0 pt-0">
              <b-card-body class="pt-3">
                <b-badge style="backgroundColor: #DDCEF0; color: #000" href="#" variant="info" class="width-badgee"
                  >{{ convKategori(item.m_kategori_konten_id)}}</b-badge
                >
                <p class="pt-3" style="font-size:16px; margin:0px; font-weight:600;">{{item.title.length > 40 ? item.title.substring(0,40)+ '...' : item.title}}</p>
                <p style="font-size:14px;opacity:0.6;">
                  {{item.content.length > 100 ? item.content.substring(0,100)+ '...' : item.content}}
                </p>
                <p class="p-custom">{{ item.tanggal_konten}}</p>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </b-col>   
    </b-row>
    </section>

    <section v-else class="d-flex justify-content-center align-items-center" style="height:60vh;">

      <h1 class="text-muted">
      - Belum Ada Konten -
      </h1>
    </section>
    <!--end::Dashboard-->
  </div>
</template>

<script>
// import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import Carousel from "@/view/layout/Components/Carousel.vue";

export default {
  name: "dashboard",
  components: {
    Carousel,
  },
  data(){
    return {
      listCMSInformasi: '',
      listCMSKegiatan: '',
      fileURL: process.env.VUE_APP_APPLICATION_FILE_URL,
      listKategori: '',
      opt:{}
    }
  },
  mounted() {
    // this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);

    // this.getProfile()
    this.getKonten()
  },
  methods: {
    replaceByDefault(e) {
      e.target.src = "/assets/images/default-thumbnail.jpg"
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    convKategori(id){
      var b = this.listKategori.find(el => {
        return el.id == id
      })
      if(b) return b.name
    },
    getKonten(){
      this.listCMSInformasi = []
      this.listCMSKegiatan = []
      this.$store
      .dispatch("getCMSByParent", 1)
      .then((res) => {
        this.listCMSInformasi = res.data
        this.opt = {
          rewind: true,
          perPage: 1,
          arrows: false,
          fixedWidth : 400,
          autoplay : true,
          gap:0,
          pagination:false,
          cover: true,
          // height:178,
          pauseOnHover: false,
        }
      })
      this.$store
      .dispatch("getCMSByParent", 2)
      .then((res) => {
        this.listCMSKegiatan = res.data
      })
      this.$store
      .dispatch("listKategoriPilihan")
      .then((res) => {
        this.listKategori = res.data
      })
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
  },
};
</script>

<style>
.card-custom-bg {
  background-color: #ffffff;
  box-shadow: 0px 4px 15px rgba(51, 51, 51, 0.15);
  border-radius: 15px;
}
.width-badge {
  width: 74px;
  height: 35px;
  font-size: 14px;
  /* padding-bottom: 24px; */

}
h2 {
  font-weight: 600;
  font-size: 16px;
}
.p-custom {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
  margin:0px;
  color: rgba(0, 0, 0, 0.38);
}

.round-image-dash{
 border-radius: 10px;
}
</style>
